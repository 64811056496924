// https://material.angular.io/guide/theming
// https://glitch.com/~material-theme-builder
@use '@angular/material' as mat;
@use './theme-overrides' as overrides;

@import './schaeffler-colors.scss';
@import './colors.scss';
// Plus imports for other components in your app.
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$schaeffler-primary: mat.define-palette($mat-schaeffler-primary, 700);
$schaeffler-accent: mat.define-palette($mat-schaeffler-secondary, 50);

// Create the theme object (a Sass map containing all of the palettes).
$schaeffler-theme: mat.define-light-theme(
  $schaeffler-primary,
  $schaeffler-accent
);

@include mat.core();
@include mat.all-component-themes($schaeffler-theme);

@include overrides.schaeffler-theme-overrides($schaeffler-theme);

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: $primary;
  background-color: $primary;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: $primary;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $primary;
}

/* You can add global styles to this file, and also import other style files */
@import 'libs/shared/styles/src/index.scss';

/***************************************************************************************************
  * UTILITIES
  */

/*
  * TailwindCSS, utility-first css framework
  * see https://tailwindcss.com/docs
  */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  overflow-y: scroll;
  overflow-x: scroll;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

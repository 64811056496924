@use '@angular/material' as mat;
@import './colors.scss';

@mixin schaeffler-theme-overrides($theme) {
  // button overrides
  .mat-button-base {
    @apply text-button;
    @apply uppercase;

    &.mat-stroked-button.mat-button-disabled {
      @apply border-border;
    }
  }

  .mat-button-base:not(.mat-fab):not(.mat-mini-fab):not(.mat-icon-button) {
    mat-icon {
      @apply -ml-1.5 mr-2 h-[18px] w-[18px] text-[18px];
    }
  }

  // snackbar overrides
  .mat-snack-bar-container {
    background: $schaeffler-black;
    color: $schaeffler-white;
    padding-bottom: 0 !important;
    padding-top: 0 !important;

    .mat-simple-snackbar {
      @apply flex-wrap;

      > span {
        @apply pt-4;
        @apply pb-4;
        min-width: 58%;
      }

      &-action {
        @apply flex;
        @apply grow;
        @apply justify-end;
        @apply mt-2;
        @apply mb-2;
        color: $schaeffler-white;
      }
    }
  }

  // tooltip overrides
  mat-tooltip-component .mat-tooltip {
    @apply whitespace-pre-line py-2 px-4 text-caption;
    background: $schaeffler-carbon-gray;
  }

  // expansion panel overrides
  mat-accordion.mat-accordion {
    mat-expansion-panel.mat-expansion-panel {
      @apply shadow-none;
      @apply border-l;
      @apply border-r;
      @apply border-b;
      @apply border-border;

      &:first-of-type {
        @apply border-t;
      }

      &.mat-expanded {
        @apply border-t;

        & + .mat-expansion-panel {
          @apply border-t;
        }
      }

      .mat-expansion-panel-header {
        @apply rounded-none;
      }

      .mat-expansion-panel-body {
        @apply p-0;
      }
    }
  }

  // tabs overrides
  .mat-tab-labels > .mat-tab-label {
    @apply opacity-100;
    @apply h-auto;
    @apply px-6;
    @apply py-4;
  }

  .mat-tab-label-active > .mat-tab-label-content {
    @apply text-link;
  }

  .mat-tab-label-content {
    @apply text-button;
    @apply uppercase;
    @apply text-medium-emphasis;
    @apply flex;
    @apply flex-col;
  }

  // Dialog overrides
  .mat-dialog-container {
    @apply bg-background-dark;
    max-height: 100vh !important;
  }
}

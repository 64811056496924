/*Schaeffler-contrast-colors*/
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

/*Schaeffler-GREENS*/
$schaeffler-green-1: #00893d; //Primary / Popular / Highlight-Color / Toasts / Notifications
$schaeffler-green-2: #005f14; //Secondary / Highlight-Color
$schaeffler-green-3: #99d0b1; //disabled primary button
$schaeffler-green-4: #e1eece; //highlighted table cells(compensation-app only)
$schaeffler-green-5: #e5f3eb; //active table background

/*Schaeffler-noColors*/
$schaeffler-transparent: transparent;

/*Schaeffler-GRAYS*/
$schaeffler-gray-0: white;
$schaeffler-gray-1: #f6f7f8; //alternative background for input-fields
$schaeffler-gray-2: #ebeef0; //hover
$schaeffler-gray-3: #dde3e6; //background
$schaeffler-gray-4: #ced5da; //border
$schaeffler-gray-5: #b6bec1;
$schaeffler-gray-6: #aab1b4;
$schaeffler-gray-7: #9ca2a5; //labels
$schaeffler-gray-8: #878d8f;
$schaeffler-gray-9: #787d7f;
$schaeffler-gray-10: #656a6c;
$schaeffler-gray-11: #55595a;
$schaeffler-gray-12: #414546; //Toast-information-background
$schaeffler-gray-13: #2d3031;

/*Schaeffler-OTHERS*/
$schaeffler-black: rgba(black, 0.87);
$schaeffler-white: #fff; //For background
$schaeffler-red: #e62c27; // Error / Warning
$schaeffler-yellow: #fccf46; // Accent
$schaeffler-blue: #1d9bb2;
$schaeffler-green-success: #0ebc5b; // Success
$schaeffler-carbon-gray: #646464; //text / secondary button
$schaeffler-carbon-gray-10: #efefef; //10% opacity carbon gray
$schaeffler-carbon-gray-40: #c1c1c1; //40% opacity carbon gray

/*custom material color palettes, see: http://mcg.mbitson.com */

// palette based on $schaeffler-green-1
$mat-schaeffler-primary: (
  50: #e5f4e9,
  100: #c0e4c9,
  200: #98d3a7,
  300: #6cc385,
  400: #49b66b,
  500: #1caa52,
  600: #129b49,
  700: #00893d,
  800: #007832,
  900: #00591f,
  A100: #8bffa1,
  A200: #58ff78,
  A400: #25ff4e,
  A700: #0bff3a,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

// palette based on $schaeffler-red
$mat-schaeffler-warn: (
  50: #fce6e5,
  100: #f8c0be,
  200: #f39693,
  300: #ee6b68,
  400: #ea4c47,
  500: #e62c27,
  600: #e32723,
  700: #df211d,
  800: #db1b17,
  900: #d5100e,
  A100: #ffc6cf,
  A200: #ff899a,
  A400: #ff7085,
  A700: #ff6077,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

// palette based on $schaeffler-yellow
$mat-schaeffler-accent: (
  50: #fff9e9,
  100: #fef1c8,
  200: #fee7a3,
  300: #fddd7e,
  400: #fcd662,
  500: #fccf46,
  600: #fcca3f,
  700: #fbc337,
  800: #fbbd2f,
  900: #fab220,
  A100: #ffffff,
  A200: #fffefd,
  A400: #ffecca,
  A700: #ffe3b1,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
